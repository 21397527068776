:root{
	--border-radius: 20px;
	--padding:30px;
	
	
	@media only screen and (max-width: 500px) {
		--border-radius: 20px;
		--padding:5px;
	}
}

@media print{
	* {
		/*display:none;*/
	}
	
	body{
		background:none !important;
		font-size:10pt !important;
	}
	
	.sidebar-inside, .navbar-toggler, a.mb-2, details, .tab, .mt-md-4, .coach-report-athlete-info-title, hr, .coach_report-athlete-info-header, .navbar-nav{
		display:none !important;
	}
	
	.results_details, .results_sports, .results_flags, .not_seen{
		display:none !important;
	}
	
	.results_overview{
		display:block !important;
	}
	
	.mainCard,.card,.ml-sm-auto,.col-xl-10,.col-md-9{
		width:100% !important;
		margin:0px !important;
		padding:10px;
	}
	
	.mainCard{
		border:0px;
	}
	
	.variable, .sub-variables{
		margin-right:0px !important;
		margin-left:0px !important;
		padding:12px !important;
	}
	
	.sub-variables{
		margin-bottom:0px !important;
	}
	
	.navbar{
		padding:0px !important;
		margin:0px !important;
	}
}

cite{
	font-size:.6rem;
	opacity:.5;
}

cite::before{
	content:"(";
}

cite::after{
	content:")";
}

summary{
	user-select:none;
}

ul{
	list-style-type: square;
}

div.html_container{
	max-width:800px;
	user-select:none;
	font-size:1rem;
}

#resultsPageJavaScript{
	height:0px;
	width:0px;
	overflow:hidden;
}

div.variable{
	background: rgba(0,0,0,.03);
	border:solid rgb(0,0,0, .05) 1px;
	border-radius:var(--border-radius);
	padding:var(--padding);
	margin:var(--padding);
	font-size:1rem;
}

div.sub-variables{
	padding:var(--padding);
	margin:var(--padding);
	background:rgba(255,255,255,.9);
	border:solid white 1px;
	border-radius:var(--border-radius);
	font-size:1rem;
}


div.variable, div.sub-variables{
	box-shadow:0px 0px 15px rgba(0,0,0,.1);
	white-space:normal;
	word-wrap:normal;
	overflow-wrap:normal;
}

div.sub-variables{
	box-shadow:0px 0px 10px rgba(0,0,0,.05);
}


div.variable h3, div.sub-variables h4{
	margin-top:50px;
	margin-bottom:0px;
}

div.sub-variables h4{
	margin-top:0px;
}


div.variable h3:first-child{
	margin-top:0px;
}

div.spacer{
	height:5px;
}

sup{
	font-size:initial;
}

p + p{
	margin-top:20px;
}

details{
	margin-left:30px;
}

summary{
	cursor:pointer;
	margin-left:-30px;
}

details summary::after{
	content: " [?]";
}


details[open] summary::after{
	content: "";
}

#rpifjs, #rpifjs2{
	height:0px;
	width:0px;
	border:0px;
	user-select:none;
}

img.not_seen{
	display:inline-block;
	border:0px;
	outline:0px;
	height:50%;
	max-height:25px;
	vertical-align:middle;
	margin-left:5px;
	opacity:.5;
	transition:opacity 1s;
	cursor:help;
	border:0px;
	box-sizing:border-box;
	padding:0px;
	margin:0px;
}

img.not_seen:hover{
	opacity:.9;
}


.lie_detection{
	background:url('https://www.leftbrainperformance.com/results/app/warning_lies.svg') !important;
	background-size:30px !important;
	background-repeat:no-repeat !important;
	background-position:12px 30px !important;
	padding:30px !important;
	padding-left:50px !important;
	display:none;
}


.tabs{
	user-select:none;
}

.tab{
	display:inline-block;
	font-size:.80rem;
	margin:0px 10px;
	padding:0px;
	padding-left:20px;
	background-image:url(https://www.leftbrainperformance.com/results/app/overview5.svg);
	background-size:15px;
	background-repeat:no-repeat;
	background-position:left center;
	position:relative;
	opacity:.70;
	border-bottom:solid transparent 2px;
	cursor:pointer;
	color:rgb(130,0,0);
	user-select:none;
	transition:all .5s;
	font-weight:bolder;
	white-space:nowrap;
}

.tab:hover{
	opacity:1;
	color:rgb(255,0,0);
}

.sports_tab{
	background-image:url(https://www.leftbrainperformance.com/results/app/sports4.svg);
}

.details_tab{
	background-image:url(https://www.leftbrainperformance.com/results/app/information2.svg);
}

.questions_tab{
	background-image:url(https://www.leftbrainperformance.com/results/app/question.svg);
}

.flags_tab{
	background-image:url(https://www.leftbrainperformance.com/results/app/flag.svg);
}



/*.tab::after{
	content:"▼";
	color:white;
	font-size:1rem;
	position:absolute;
	bottom:-15px;
	left:50%;
}*/



.tab_content{
	display:none;
	margin-top:15px;
}

.results_overview, .results_sports, .results_details, .results_questions, .results_flags{
	display:none;
}


.tabs[data-active-tab='results_overview'] .results_overview, .tabs[data-active-tab='results_sports'] .results_sports, .tabs[data-active-tab='results_details'] .results_details, .tabs[data-active-tab='results_questions'] .results_questions, .tabs[data-active-tab='results_flags'] .results_flags{
	display:block;
}


.tabs[data-active-tab='results_overview'] .overview_tab, .tabs[data-active-tab='results_sports'] .sports_tab, .tabs[data-active-tab='results_details'] .details_tab, .tabs[data-active-tab='results_questions'] .questions_tab, .tabs[data-active-tab='results_flags'] .flags_tab{
	opacity:1;
	border-bottom:solid rgb(var(--theme-color)) 2px;
	font-weight:bolder;
	color:rgb(var(--text-color));
}


.high_score, .average_score, .low_score{
	display:none;
}


.lbp_variable_data{
	display:none;
}

.progress, .progress-done{
		border-radius:20px;
}

.progress{
	outline-style:solid;
	outline-color:rgb(200,200,200);
	outline-width:1px;
	box-shadow:inset 0px 0px 10px rgba(0,0,0,.05), 3px 3px 5px rgb(0,0,0,.05);
	height:35px;
}



.progress-done{
	text-align:left;
	justify-content:left;
	overflow:visible;
	font-weight:bolder;
	box-sizing:border-box;
	padding-left:15px;
	white-space:pre;
	text-shadow:0px 0px 3px rgba(0,0,0,.9);
	font-size:.8rem;
	border-top-right-radius:0px;
	border-bottom-right-radius:0px;	
	box-shadow:inset 0px 0px 15px rgba(255,255,255,.5);
}

	.progress-done.dark_red{
		background:linear-gradient(270deg,rgb(100,0,0),rgb(200,50,50));
	}

	.progress-done.red{
		background:linear-gradient(270deg,rgb(175,0,0),rgb(255,78,78));
	}

	.progress-done.light_red{
		background:linear-gradient(270deg,rgb(244,67,54),rgb(255,180,175));
	}

	.progress-done.orange{
		background:linear-gradient(270deg,rgb(206,80,40),rgb(255,120,100));
	}

	.progress-done.gold{
		background:linear-gradient(270deg,rgb(231,159,17),rgb(238,206,141));
	}

	.progress-done.lime{
		background:linear-gradient(270deg,rgb(139,195,74),rgb(206,229,136));
	}

	.progress-done.green{
		background:linear-gradient(270deg,rgb(15,115,15),rgb(75,205,75));
	}

	.progress-done.dark_green{
		background:linear-gradient(270deg,rgb(0,91,10),rgb(147,168,151));
	}

	.progress-done.cyan{
		background:linear-gradient(270deg,rgb(0,188,212),rgb(141,235,248));
	}

	.progress-done.light_blue{
		background:linear-gradient(270deg,rgb(33,150,243),rgb(160,203,237));
	}

	.progress-done.navy_blue{
		background:linear-gradient(270deg,rgb(0,26,107),rgb(0,119,251));
	}

	.progress-done.purple{
		background:linear-gradient(270deg,rgb(156,39,176), rgb(240,158,255));
	}
	
	.progress-done.dark_purple{
		background:linear-gradient(270deg,rgb(120,20,156), rgb(200,50,220));
	}
	
	.progress-done.fuscia{
		background:linear-gradient(270deg,rgb(233,30,182),rgb(255,179,236));
	}


.red_flag, .orange_flag, .yellow_flag, .green_flag, .blue_flag{
	display:inline-block;
	cursor:help;
	margin:0px;
	padding:0px;
	vertical-align:middle;
	max-height:.75em;
	min-height:20px;
	margin-left:10px;
	filter:drop-shadow(0px 0px 2px rgb(255,255,255)) drop-shadow(0px 0px 1px rgb(255,255,255)) drop-shadow(0px 0px 5px rgba(0,0,0,.1)) drop-shadow(0px 0px 5px rgba(0,0,0,.1));
}

.negflag, .posflag{
	display:none;
}

.noclassflag > .tabs > .flags_tab{
	display:none !important;
}

.ideal_zone{
	height:5px;
	border-radius:var(--border-radius);
	background:green;
	width:100%;
}