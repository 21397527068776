#logout {
  font-size: 90%;
}

#payButton {
  float: right;
  width: fit-content;
  margin-bottom: 30px;
}

#modalTitle {
  padding: 5px;
}

#modalActions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
