.answerButton {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  background-color: #007bff17;
  border-radius: 0 !important;
}
@media (hover: none) {
  .answerButton:hover {
    background-color: #007bff17;
    color: #007bff;
  }
}

.questionTitle {
  font-size: large;
  font-weight: bold;
  margin-bottom: 15px;
}

.backButton {
  float: left;
  border-radius: 0 !important;
}

.submitButton {
  float: right;
}

.save-and-return-link {
  font-size: smaller;
}
