.coach-portal-athlete-listing th svg {
  float: right;
  margin-top: 5px;
}

.coach-portal-athlete-listing .filter-label {
  display: none;
}

.coach-portal-athlete-listing > div:not(.mobile-view) .search-label {
  float: right;
}

.coach-portal-athlete-listing .pagination {
  float: right;
}

.coach-portal-athlete-listing table tr td:first-child a {
  font-size: 0.9rem;
}

.coach-portal-athlete-listing .mobile-report-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
}

.coach-portal-athlete-listing .teams-header {
  font-weight: 600;
  margin-top: 5px;
}

.sidebarcoach-container .nav-item {
  margin-left: 15px;
  margin-top: 10px;
}