@import './css/style.css';
@import './css/mobile.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import './components/css/PayMessage.css';
@import './components/css/Header.css';
@import './components/css/Question.css';
@import './components/css/CoachPortalAthleteListing.css';
@import './components/css/CoachPortalSidebar.css';
@import './components/css/CoachPortalBreadcrumbs.css';
@import './pages/css/Assessment.css';
@import './pages/css/Form.css';
@import './pages/css/Login.css';
@import './pages/css/ResultPage.css';
@import './pages/css/Profile.css';

* {
  margin: 0;
  padding: 0;
}

.card,
.btn {
  border-radius: var(--border-radius);
}

.mainCard {
  min-height: calc(100vh - 100px);
}

.App {
  margin-bottom: 0.5rem;
  min-height: 400px; /* pushes footer down on very short pages */
}

/* logged-in layout, sidebar, etc. */
.sidebar {
  z-index: 100;
  padding: 0;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
  background-color: #ffffffba;
}

.sidebar-inside {
  min-height: calc(100vh - 100px);
  padding-top: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.school-logo {
    width: 90%;
    margin: 0 auto;
    display: block;
    padding-right: 10px;
    max-height: 100px;
    max-width: 200px;
}

.sidebar .nav-link {
  color: rgb(130, 0, 0);
  font-weight: 500;
}
.sidebar .nav-link.disabled {
  color: grey;
}

.sidebar .nav-link.active {
  font-weight: bold;
}

.sidebar .nav-item span {
  margin-left: 10px;
}

input[type='email'] {
  min-width: 0px;
}

input[type='text'] {
  min-width: 0px;
}

input[type='password'] {
  min-width: 0px;
}

#pageDropDown {
  border-radius: 0.25rem !important;
}

.navbar-nav {
    align-items: center;
}