@media only screen and (max-width:800px), only screen and (max-device-width:800px){
	.grid_1_1_1_1{
		grid-template-columns:1fr 1fr;
	}
	
	.popup_window{
		width:75%;
	}
}

@media only screen and (max-width:750px), only screen and (max-device-width:750px){
	
}

@media only screen and (max-width:700px), only screen and (max-device-width:700px){
	.grid_1_1_1_1, .grid_1_1_1{
		grid-template-columns:initial;
		place-content:start stretch;
		place-items:start stretch;
	}

	header.small{
		place-content:center stretch;
		padding-left:15px;
		padding-right:15px;
		box-sizing:border-box;
	}
	
	h1.page_title, h1.title{
		font-size:3.5rem;
	}
}

@media only screen and (max-width: 625px), only screen and (max-device-width:625px){
	:root{
		--content-width:100%;
		--content-min-width:auto;	
	}	
	
	header{
		height:75px;
		min-height:75px;
		padding:15px;
	}
	
	.mobile_padding{
		padding-left:15px;
		padding-right:15px;
	}
	
	html{
		font-size:11pt;
	}
	
	.padded{
		padding-right:15px;
		padding-left:15px;
	}
	
	h1.page_title, h1.title{
		font-size:3rem;
	}	
	
	h1.page_title{
		margin-top:200px;
	}
	
	.grid_2_1, .grid_1_2, .grid_1_1, .grid_3_1, .grid_1_3, .grid_1_1_1, .grid_1_1_1_1{
		grid-template-columns:initial;
		place-content:start stretch;
		place-items:start stretch;
	}
	
	section > .glass:first-child, section > div:first-child > .glass:first-child{
		margin-top:calc(var(--grid-gap) * -1.5);
	}
	
	#section_menu + section > .glass:first-child, #section_menu + section > div:first-child > .glass:first-child, #section_menu + script + section > .glass:first-child, #section_menu + script + section > div:first-child > .glass:first-child{
		margin-top:0px;
	}
	
	#section_menu a{
		padding:10px 7px;
		margin-right:2px;
	}
	
	section > .glass:last-child, section > div:last-child > .glass:last-child{
		margin-bottom:calc(var(--grid-gap) * -1.5);
	}
	
	.chart{
		width:100%;
	}
	
	#footer_grid{
		grid-gap:50px;
	}
	
	#footer_grid div{
		text-align:center;
	}
	
	.footer_menu_separator{
		margin:4px auto;
	}
	
	.small #logo{
		justify-self:start;
	}
	
	.small_container{
		width:calc(var(--content-width));
		max-width:calc(var(--content-max-width));
		min-width:calc(var(--content-min-width));
		margin:0px auto;
		box-sizing:border-box;	
	}

	.no_mobile{
		display:none;
	}
	
	.popup_window{
		width:95%;
		padding:25px 10px;
	}
}

@media only screen and (max-width:350px), only screen and (max-device-width: 350px){
	.small #logo{
		height:45px;
		justify-self:start;
	}
}