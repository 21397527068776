.page-login img,
.page-register img {
  display: block;
  margin: 0 auto;
  max-width: 50%;
}

.page-login h2,
.page-register h2 {
  font-size: 1.75rem;
  margin-bottom: 1.75rem;
  text-align: center;
  font-family: 'Trebuchet MS', Helvetica;
}
