@media only screen and (max-width:1200px), only screen and (max-device-width: 1200px){
	div.variable div.sub-variables{
		margin-left:0px;
		margin-right:0px;
	}
}

@media only screen and (max-width:575px), only screen and (max-device-width: 575px){
	
	div.variable, div.variable div.sub-variables{
		padding:15px;
		margin:15px 0px;
	}
	
	.progress{
		height:36px !important;
	}

	h1{
		font-size:1.65rem !important;
		font-weight:bolder !important;
	}

	h3{
		font-size:1.25rem !important;
		font-weight:bolder !important;
	}

	h4{
		font-size:1.1rem !important;
	}

	p, .page_results p, .html_page p, div.variable, div.sub-variables, div.html_container{
		font-size:0.9rem !important;
	}
}