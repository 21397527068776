:root{
	--glass-color: 255,255,255;
	--glass-alpha: .9;
	--glass-blur: 9px;
	--section-blur:3px;
	
	--menu-highlight: rgba(255,255,255,.33);

	--border-radius: 15px;
	
	--content-width: calc(100vw - 100px);
	--content-max-width: 1200px;
	--content-min-width: 600px;
	
	--content-padding:30px 35px;
	--grid-gap:30px;
	
	--theme-color:0,60,150;
	--theme-color-dark:0,15,50;
	--theme-color-dark-desaturated:10,20,50;
	--tertiary-color:255,165,0;
	--theme-color-light:75,100,255;
	--accent-color:240,100,55;
	--text-color:0,20,75;

	--link-color:130,0,0;
	--link-hover-color:255,0,0;

	--background-image:url(../img/template/bg/jumping_grey_m.jpg);
	
	--shadow: drop-shadow(0px 0px 5px rgb(var(--theme-color-dark)));
}

@media only screen and (max-width:600px), only screen and (device-max-wdith:600px){
	:root{
		--background-image:url(../img/template/bg/jumping_grey_s.jpg);
	}
}

*{
	outline:0px;
}

html{
	font-size:12pt;
	scroll-behavior:smooth;
}

body{
	margin:0px;
	padding:0px;
	font-size:1.05rem;
	font-family:seaford, oswald, helvetica, arial, ovo, sans-serif;
	background:linear-gradient(rgba(var(--theme-color),.50) 60vh, rgba(var(--theme-color),.20) 100vh, rgba(var(--theme-color),.3)) fixed, var(--background-image) no-repeat 35% top fixed, rgb(var(--theme-color));
	background-size:cover;
	line-height:150%;
	color:rgb(var(--text-color));
}

body, .glass{
	color:rgb(var(--text-color));	
}

h1, h2, h3, h4, h5, h6{
	font-family: gill sans mt, amaranth, trebuchet ms, helvetica, sans-serif;
	color:rgb(var(--theme-color));
	margin-top:calc(var(--grid-gap) * 2);
	line-height:133%;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child{
	margin-top:0px;
}

h1{ font-size: 2.25rem; margin-bottom:2rem;}
h2{ font-size: 1.75rem; margin-bottom:1.75rem;}
h3{ font-size: 1.75rem; font-weight:normal; }
h4{ font-size: 1.5rem; margin-bottom:1.5rem;}
h5{ font-size: 1.5rem; margin-bottom:1.5rem; font-weight:normal;}
h6{ font-size: 1.1rem; margin-bottom:1.1rem;}

h1.page_title, h1.title{
	color:rgb(var(--glass-color));
	font-size:3rem;
}

.lbp h1.page_title{
	font-size:4rem;
	line-height:67%;
	margin-top:1rem;
	margin-top:300px;
	z-index:9;
	position:relative;
}

h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p{
	margin-top:0px;
}

p + p {
	margin-top:30px;
}

a, .summary, summary{
	text-decoration:none;
	color:rgb(130,0,0);
	transition:color .5s;
}

a:hover, .summary:hover, summary:hover{
	color:rgb(255,0,0);
}

header{
	user-select:none;
	padding:max(4vh,25px) 10px;
	background:rgba(255,255,255,.9);
	background-image:url(../img/template/hb.png);
	background-repeat: repeat-x;
	background-position:center center;
	background-size:auto 50%;
	vertical-align:middle;
	box-sizing:border-box;
	height:15vh;
	min-height:100px;
	position:fixed;
	width:100%;
	z-index:99999;
	backdrop-filter:blur(var(--glass-blur));
	top:0;
	left:0;
	border-bottom:solid white 1px;
}

header .container{
	height:100%;
	vertical-align:middle;
}

header .container #menu_wrapper1{
	float:right;
	display:table;
	height:100%;
}

header .container #menu_wrapper2{
	vertical-align:middle;
	display:table-cell;
	position:relative;
	border:0px;
}


#menu_wrapper2 a.button, #menu_wrapper2 div.button{
	background:rgba(255,255,255,.85);
	color:rgb(var(--theme-color));
	border-color:rgb(var(--theme-color));
	padding:7px 10px;
}

#menu_wrapper2 > a.button:first-of-type{
	border-top-right-radius:0px;
	border-bottom-right-radius:0px;
}


#menu_wrapper2 > div.button{
	border-top-left-radius:0px;
	border-bottom-left-radius:0px;
	border-left:0px;
}

#menu_icon{
	display:inline-block;
	cursor:pointer;
	border-radius:var(--border-radius);
	align-self:start;
	margin-bottom:0px;
	vertical-align:middle;
}
	
#menu_icon div{
	background:rgb(var(--theme-color));
	width:18px;
	height:2px;
	margin:4px;
	border-radius:2px;
	transition:all 1s;
}

#menu_icon div:first-of-type{
	margin-top:1px;
}

#menu_icon div:nth-of-type(2){
	height:2px;
}

#menu{
	position:absolute;
	width:100%;
	border:solid rgb(var(--theme-color)) 1px;
	border-radius:var(--border-radius);
	border-top-right-radius:0px;
	border-top-left-radius:0px;
	/*overflow:hidden;*/
	left:0px;
	border-top:0px;
	display:none;
	box-sizing:border-box;
}

#menu > a{
	display:block;
	padding:7px 10px;
	background:rgba(255,255,255,.95);
	color:rgb(var(--theme-color));
	backdrop-filter:blur(var(--glass-blur));
	transition:filter .5s;
	font-size:.95rem;
}

#menu > a.end_section{
	border-bottom:solid rgba(var(--theme-color), .45) 1px;
}

#menu > a:last-of-type{
	border-bottom-left-radius:var(--border-radius);
	border-bottom-right-radius:var(--border-radius);
}

#menu_wrapper2{
	border:0px;
}

#menu_wrapper2[data-open='yes'] > a.button:first-of-type{
	border-bottom-left-radius:0px;
	border-bottom-right-radius:auto;
	border-bottom-color:rgba(255,255,255,.85);
}

#menu_wrapper2[data-open='yes'] > div.button{
	border-bottom-right-radius:0px;
}

#menu_wrapper2[data-open='yes'] > #menu{
	display:block;
}

#menu_wrapper2[data-open='yes'] #menu_icon div:nth-of-type(1){
	transform:rotate(45deg);
	margin-top:0px;
}
#menu_wrapper2[data-open='yes'] #menu_icon div:nth-of-type(2){
	opacity:0;
}
#menu_wrapper2[data-open='yes'] #menu_icon div:nth-of-type(3){
	transform:rotate(-45deg);
	margin-top:-12px;
}

#logo{
	max-height:100%;
	transition:filter .5s;
}

#logo:hover, .hover_glow:hover{
	cursor:pointer;
	filter:drop-shadow(0px 0px 5px rgb(var(--tertiary-color)));
	text-shadow:(0px 0px 5px rgb(var(--tertiary-color)));
}

.hover_glow{
	transition:.5s;
}

img{
	display:inline-block;
	max-width:100%;
	outline:0px;
	user-select:none;
}

ol li, ul li{
	margin-bottom:1rem;
}

tr{
	vertical-align:top;
}

td{
	padding:5px;
}

ul, ol{
	padding-left:35px;
}

footer{
	background:rgba(var(--theme-color-dark),.8);
	color:rgba(255,255,255,.6);
	margin-top:calc(var(--grid-gap) * 2);
	padding:75px 0px;
	font-size:.9rem;
	backdrop-filter: blur(var(--glass-blur));
	border-top:solid rgba(var(--theme-color-dark),.85)) 1px;
	user-select:none;
}

footer a{
	color:rgba(255,255,255,.6);
}

footer a:hover{
	color:rgb(var(--tertiary-color));
	filter:none;
}

footer a.button{
	border-color:rgba(255,255,255,.6);
	background:rgba(255,255,255,.1);
}

footer a.button:hover{
	border-color:rgba(255,255,255,.6);
	background:rgba(255,255,255,.1);
	color:rgb(var(--tertiary-color));
	color:white;
}

footer h6{
	color:rgba(255,255,255,.6);	
}

#footer_grid{
	place-items:center center;
	place-content:center center;
}

#footer_logo{
	opacity:.5;
	height:36px;
	display:inline-block;
	transition:opacity .5s;
}

#footer_logo:hover{
	opacity:.9;
}

.footer_menu_separator{
	width:60%;
	background:white;
	height:1px;
	opacity:.25;
	margin:4px 0px;
}

#media_icons{
	display:grid;
	grid-template-columns:repeat(5,auto);
	grid-gap:20px;
	place-content:center start;
	place-items:center start;
}

#media_icons img{
	display:inline-block;
	opacity:.5;
	min-width:32px;
	min-height:32px;
	max-height:32px;
	max-width:32px;
	transition:opacity .5s;
}

#media_icons img:hover{
	opacity:.9;
}

.white_icon{
	opacity:.5;
	transition:opacity .5s;
}

#hero .white_icon{
	opacity:.33;
}

.white_icon:hover, #hero .white_icon:hover{
	opacity:1;
}

#contact_icon{
	opacity:.5;
	height:.75rem;
	display:inline-block;
}

#contact_icon:hover{
	opacity:.9
}

cite{
	font-style:normal;
	font-size:.8em;
	opacity:.75;
}

cite::before{
	content:"(";
}

cite::after{
	content:")";
}

code{
	font-size:.8rem;
}
	
.lbp .container{
	width:var(--content-width);
	max-width:var(--content-max-width);
	min-width:var(--content-min-width);
	margin:0px auto;
	box-sizing:border-box;
}

.small_container{
	width:calc(var(--content-width)*.7);
	max-width:calc(var(--content-max-width)*.7);
	min-width:calc(var(--content-min-width)*.7);
	margin:0px auto;
	box-sizing:border-box;	
}

p:first-child{
	margin-top:0px;
}

p:last-child{
	margin-bottom:0px;
}

.centered{
	margin:0px auto;
	justify-self:center;
}
	
.padded{
	padding:var(--content-padding);
	box-sizing:border-box;
}

.glass{
	background:linear-gradient(rgba(var(--glass-color),.75), rgba(var(--glass-color),var(--glass-alpha)) 50px);
	border:solid rgba(var(--glass-color),.var(--glass-alpha)) 1px;
	border-radius:var(--border-radius);
	backdrop-filter: blur(var(--glass-blur));
}


.rounded{
	border-radius: var(--border-radius);
}

.grid_2_1{
	grid-template-columns:2fr 1fr;
}

.grid_1_2{
	grid-template-columns:1fr 2fr;
}

.grid_1_1{
	grid-template-columns:1fr 1fr;
}

.grid_3_1{
	grid-template-columns:3fr 1fr;
}

.grid_1_3{
	grid-template-columns:1fr 3fr;
}

.grid_1_1_1{
	grid-template-columns:1fr 1fr 1fr;
}

.grid_1_1_1_1{
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid_1_1_1_1_1{
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid, .grid_2_1, .grid_1_2, .grid_1_1, .grid_3_1, .grid_1_3, .grid_1_1_1, .grid_1_1_1_1, .grid_1_1_1_1_1{
	display:grid;
	grid-gap:var(--grid-gap);
	place-items:stretch stretch;
}

.grid{
	place-items:start stretch;
	place-content:start start;
}

.grid_2_1 img, .grid_1_2 img, .grid_2_2 img, .grid_3_1 img, .grid_1_3 img, .grid_1_1_1 img, .grid_1_1_1_1 img{
	max-width:100%;
}

.double_gap{
	grid-gap:calc(var(--grid-gap) * 2);
}

.triple_gap{
	grid-gap:calc(var(--grid-gap) * 3);
}

.spacer{
	height:calc(var(--grid-gap));
	width:calc(var(--grid-gap));
}

.double_spacer{
	height:calc(var(--grid-gap) * 2);
	width:calc(var(--grid-gap) * 2);
}

.triple_spacer{
	height:calc(var(--grid-gap) * 3);
	width:calc(var(--grid-gap) * 3);
}

.gap_top{
	margin-top:var(--grid-gap);
}

section{
	backdrop-filter:blur(var(--section-blur));
}

section, #section_menu{
	background:rgba(var(--glass-color),.7);
	padding-top:calc(var(--grid-gap) * 3);
	padding-bottom:calc(var(--grid-gap) * 3);
}

#section_menu{
	padding-top:var(--grid-gap);
	padding-bottom:var(--grid-gap);
}

#section_menu + section, #section_menu + script + section{
	padding-top:0px;
}

#section_menu a{
	display:inline-block;
	padding:15px;
	margin-right:5px;
	color:rgb(var(--theme-color));
	border-radius:var(--border-radius);
	transition:background .5s, color .5s;
}

#section_menu a:hover, #section_menu a.page_open{
	background:rgba(var(--glass-color),.7);
	color:rgb(var(--theme-color-light));
}

section:nth-of-type(2n){
	background:rgba(var(--theme-color-dark-desaturated), .7);
	color:white;
}

section h1, section h2{
	text-shadow:0px 0px 10px white, 0px 0px 15px white, 0px 0px 20px white;
}

section:nth-of-type(2n) h1, section:nth-of-type(2n) h2, section:nth-of-type(2n) h3, section:nth-of-type(2n) h4, section:nth-of-type(2n) h5, section:nth-of-type(2n) h6{
	color:white;
	text-shadow:none;
}

.glass:nth-of-type(1n) h1, .glass:nth-of-type(1n) h2, .glass:nth-of-type(1n) h3, .glass:nth-of-type(1n) h4, .glass:nth-of-type(1n) h5, .glass:nth-of-type(1n) h6{
	color:rgb(var(--theme-color));
	text-shadow:none;
}

section > .container > h1{
	text-align:center;
}

a.button, div.button{
	display:inline-block;
	border:solid white 1px;
	border-radius:var(--border-radius);
	padding:15px;
	transition:filter .5s, color .5s, background .5s;
	cursor:pointer;
	color:white;
	background:rgba(255,255,255,.1);
	backdrop-filter:blur(var(--glass-blur));
	user-select:none;
}

a.button:hover, a.button_dark:hover, div.button:hover, #menu > a:hover{
	filter:drop-shadow(0px 0px 5px rgb(var(--tertiary-color)));
}

a.button_dark{
	display:inline-block;
	border:solid white 1px;
	border-radius:var(--border-radius);
	padding:15px;
	transition:filter .5s, color .5s, background .5s;
	backdrop-filter:blur(var(--glass-blur));
	background:rgba(var(--accent-color),.9);
	text-shadow:none;
	border-color:white;
	color:white;
	user-select:none;
}

.circle_img{
	width:100%;
	padding-top:100%;
	background:url('../img/template/bg/pushups.jpg') 50% 15%;
	background-size:cover;
	border-radius:100%;
}

input[type='text'], input[type='password'], textarea, input[type='button'], input[type='submit'], select{
	font-size:1.05rem;
	font-family:calibri, oswald, helvetica, arial, sans-serif;
	line-height:150%;
	background:rgba(var(--glass-color),.35);
	padding:8px 14px;
	margin:10px 0px;
	display:block;
	min-width:300px;
	width:75%;
	border:solid white 1px;
	border-radius: var(--border-radius);
	transition:background .5s, border-color .5s, color .5s;
	max-width:90%;
	box-sizing:border-box;
	color:rgb(var(--text-color));
	outline:0px;
	backdrop-filter:blur(var(--glass-blur));
}

input[type='text']:focus, input[type='password']:focus, textarea:focus, input[type='button']:hover, input[type='submit']:hover, select:focus{
	border-color:rgb(var(--theme-color-light));
	background:rgba(var(--glass-color),.65);
}

input[type='button'], input[type='submit']{
	width:initial;
	min-width:initial;
	padding:15px 20px;
	cursor:pointer;
}

.invalid{
	background:rgba(255,200,200,.3) !important;
	background-image:url(../img/template/warning.svg) !important;
	background-position:center right 15px !important;
	background-repeat:no-repeat !important;
	background-size:auto 50% !important;
	border:solid red 1px !important;
	cursor:help;
}

input[type='submit'].invalid{
	cursor:not-allowed;
	background-image:none !important;
}

textarea{
	min-height:200px;
	resize:vertical;
}

textarea.code{
	font-family:lucida console, monospace;
	resize:both;
	max-width:100%;
	font-size:.8rem;
}

.summary{
	cursor:pointer;	 
	user-select:none;
}

.details{
	display:none;
}

.summary[data-open='open'] + .details{
	display:block;
}

.faq .summary{
	font-size:1.33rem;
	font-weight:bolder;
	margin-top:var(--grid-gap);
}

.faq .summary::before{
	content: "►";
	font-size:.6em;
	vertical-align:text-top;
	margin-right:5px;
	display:inline-block;
	transition:all .25s;
}

.faq .summary[data-open='open']::before{
	transform: rotate(90deg);
}

.faq .summary:first-of-type{
	margin-top:0px;
}

.faq .summary, .faq .details{
	padding:var(--content-padding);
	background:rgb(var(--glass-color), var(--glass-alpha));
	border-radius:var(--border-radius);
	box-shadow:0px 0px 2px rgba(var(--theme-color), .2);
}

.faq .summary[data-open='open']{
	margin-bottom:1px;
	border-bottom-left-radius:0px;
	border-bottom-right-radius:0px;
}

.faq .details{
	border-top-left-radius:0px;
	border-top-right-radius:0px;
}

.indented{
	margin-left:var(--grid-gap);
}

.popup{
	width:100%;
	height:100%;
	background:rgba(255,255,255,.7);
	backdrop-filter:blur(var(--glass-blur));
	position:absolute;
	z-index:999999999999;
	left:0px;
	top:0px;
	position:fixed;
	box-sizing:border-box;
	opacity:0;
	user-select:none;
	display:table;
}

.popup[data-open='yes']{
	animation:popup;
	animation-duration:1s;
	opacity:1;
	transition:opacity 1s;
	overflow:auto;
}

.popup[data-open='no']{
	animation:popout;
	animation-duration:1s;
	opacity:0;
}

.popup img{
	display:block;
	margin:0px auto;
	max-width:50%;
}

.popup_wrapper{
	display:table-cell;
	text-align:center;
	vertical-align:middle;
}

.popup_window{
	display:inline-block;
	width:60%;
	min-width:350px;
	max-width:600px;
	min-height:100px;
	max-height:95vh;
	background:rgba(255,255,255,.9);
	border-radius:var(--border-radius);
	border:solid white 1px;
	box-shadow:0px 0px 20px rgba(var(--theme-color-dark),.75),0px 0px 10px rgba(var(--theme-color-dark),.75);
	padding:50px 20px;
	box-sizing:border-box;
	text-align:center;
	position:relative;
	overflow:auto;
}

.popup_window h2{
	margin-top:0px;
}

.popup_window input, .signin input{
	border:solid rgb(var(--theme-color)) 1px;
	margin-left:auto;
	margin-right:auto;
	background:rgba(var(--theme-color),.05);
	transition:filter .5s, background .5s, border .5s;
}

.popup_window input:focus, .signin input:focus{
	border:solid rgb(var(--theme-color-light)) 1px;
	background:rgba(var(--tertiary-color),.1);
}

.popup_window input[type=submit]:hover, .signin input[type=submit]:hover{
	border:solid rgb(var(--theme-color-light)) 1px;
	background:rgba(255,255,255,.5);	
	filter:drop-shadow(0px 0px 5px rgb(var(--tertiary-color)));
	color:rgb(var(--theme-color));
}

.popup_close{
	position:absolute;
	top:8px;
	right:10px;
	font-size:2.5rem;
	transition:color .5s;
	cursor:pointer;
	user-select:none;
}

.popup_close:hover{
	color:rgb(200,0,0);
}

.signin{
	text-align:center;
}

@keyframes popup{
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}

@keyframes popout{
	0%{
		opacity:1;
	}
	100%{
		opacity:0;
	}
}

.checkbox{
	min-height:40px;
	min-width:40px;
}

.noscript{
	padding:100px 50px;
	display:block;
	position:fixed;
	width:100vw;
	height:100vh;
	top:0px;
	left:0px;
	background:rgba(var(--theme-color-dark),.8);
	backdrop-filter:blur(8px);
	color:white;
	z-index:9999999999999999999999999999999;
	box-sizing:border-box;
	text-align:center;
}

.noscript img{
	max-width:33%;
	min-width:300px;
}

.noscript h1, .noscript h2{
	color:white;
}