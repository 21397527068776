code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
  border: 2px solid #ff6565;
}

.error-message {
  color: #ff6565;
  padding: 0 0.2em;
  min-height: 1em;
  font-size: 0.8em;
}

* {
  outline: 0px;
}

html {
  font-size: 12pt;
  scroll-behavior: smooth;
}

a,
.summary {
  text-decoration: none;
  color: rgb(130, 0, 0);
  transition: color 0.5s;
}

a:hover,
.summary:hover {
  color: rgb(255, 0, 0);
}

@font-face {
  font-family: 'Seaford Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Seaford Regular'), url('./assets/SeafordRg.woff') format('woff');
}

@font-face {
  font-family: 'Seaford Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Seaford Italic'), url('./assets/SeafordRgIt.woff') format('woff');
}

@font-face {
  font-family: 'Seaford Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Seaford Bold'), url('./assets/SeafordBold.woff') format('woff');
}

@font-face {
  font-family: 'Seaford Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Seaford Bold Italic'),
    url('./assets/SeafordBdIt.woff') format('woff');
}
