/* Styles */

.cardContainer {
  height: 100%;
}

/*.formStyle {*/
/*  margin: 0 auto;*/
/*  width: 50%;*/
/*}*/

.nextButton {
  float: right;
}

/* ANIMATION CSS */
/* When the component appears animations */
.slide-up-appear {
  transform: translate3d(0, 100%, 0);
}

.slide-up-appear.slide-up-appear-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
}

/* Slide Up animation CSS */
/* When new Slide up Component Enters CSS */
.slide-up-enter {
  z-index: 10;
  transform: translate3d(0, 100%, 0);
  opacity: 0;
}

.slide-up-enter.slide-up-enter-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
  z-index: 1;
  opacity: 1;
}

/* When Slide up Component Exits CSS */
.slide-up-exit {
  z-index: 1;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.slide-up-exit-active {
  transform: translate3d(0, -200%, 0);
  transition: all 500ms ease-in-out;
  opacity: 0;
}

.slide-up-exit-done {
  transform: translate3d(0, -200%, 0);
  opacity: 0;
}

/* Slide Down animation CSS */
.slide-down-enter {
  z-index: 10;
  transform: translate3d(0, -100%, 0);
  opacity: 0;
}

.slide-down-enter.slide-down-enter-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
  z-index: 1;
  opacity: 1;
}

.slide-down-exit {
  z-index: 1;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.slide-down-exit-active {
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
  opacity: 0;
}

.slide-down-exit-done {
  transform: translate3d(0, 0, 0);
  opacity: 0;
}
