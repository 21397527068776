.coach-view-athlete-info {
  font-size: 0.9rem;
}

.coach_report-athlete-info-header {
  font-weight: bold;
}

.coach-report-athlete-info-title {
  font-weight: bold;
  font-size: larger;
}

.progress {
  background-color: #d8d8d8;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 30px;
  width: 95%;
}

.progress-done {
  background: linear-gradient(to left, #4caf50, #a5cda7);
  box-shadow: 0 3px 3px -5px #999, 0 2px 5px #999;
  border-radius: 20px;
  color: #fff;
  text-shadow: 0 0 3px #000;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  white-space: pre;
  padding-left: 15px;
}

.progress-done.style-1 {
  background: linear-gradient(to left, #00bcd4, #8debf8);
}
.progress-done.style-2 {
  background: linear-gradient(to left, #9c27b0, #f09eff);
}
.progress-done.style-3 {
  background: linear-gradient(to left, #e79f11, #eece8d);
}
.progress-done.style-4 {
  background: linear-gradient(to left, #2196f3, #a0cbed);
}
.progress-done.style-5 {
  background: linear-gradient(to left, #f44336, #ffb4af);
}
.progress-done.style-6 {
  background: linear-gradient(to left, #8bc34a, #cee588);
}
.progress-done.style-7 {
  background: linear-gradient(to left, #e91eb6, #ffb3ec);
}

.page-results sup {
  font-size: 1.5rem;
}
.page-results .sub-variables {
  margin-left: 50px;
  margin-right: 50px;
}

/* medium */
@media (max-width: 991px) {
  .page-results .sub-variables {
    margin-left: 50px;
    margin-right: 0;
  }
}

/* small */
@media (max-width: 767px) {
  .page-results .sub-variables {
    margin-left: 40px;
    margin-right: 0;
  }
}

/* x-small */
@media (max-width: 575px) {
  .page-results sup {
    font-size: 1rem;
  }
  .page-results h1 {
    font-size: 1.5rem;
  }

  .page-results .progress {
    height: 20px;
  }

  .page-results .sub-variables {
    margin-left: 20px;
    margin-right: 0;
  }

  .page-results h3,
  .page-results h4,
  .page-results p {
    font-size: smaller;
  }
}
