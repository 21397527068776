.coach-portal-sidebar {
  margin-left: 15px;
  border-top: 1px solid #777;
  margin-right: 10px;
  padding-top: 10px;
  margin-top: 10px;
}
.coach-portal-sidebar .sidebar-link {
  display: block;
  padding: 0;
  color: #333;
  text-align: left;
  font-size: 0.9rem;
}
.coach-portal-sidebar .sidebar-link:not(.allsports) {
  padding-left: 20px;
}
.coach-portal-sidebar svg {
  margin-right: 5px;
  margin-top: -4px;
}
.coach-portal-sidebar svg.fa-circle-small {
  font-size: 0.5rem;
  top: -2px;
  position: relative;
}

.coach-portal-sidebar .collapse,
.coach-portal-sidebar .collapsing {
  padding-left: 20px;
}
