header {
  position: relative;
}

.lbp .container {
  padding: 0;
}

header,
header a.button,
header div.button {
  backdrop-filter: none;
}

input[type='email'],
input[type='tel'],
.lbp-field-wrapper .form-control {
  font-size: 1.05rem;
  font-family: calibri, oswald, helvetica, arial, sans-serif;
  line-height: 150%;
  background: rgba(var(--glass-color), 0.35);
  padding: 8px 14px;
  margin: 10px 0px;
  display: block;
  width: 75%;
  border: solid white 1px;
  border-radius: var(--border-radius);
  transition: background 0.5s, border-color 0.5s, color 0.5s;
  max-width: 90%;
  box-sizing: border-box;
  color: rgb(var(--text-color));
  outline: 0px;
  backdrop-filter: blur(var(--glass-blur));
}

.lbp-field-wrapper .form-control,
input[type='email'],
input[type='text'],
input[type='tel'],
input[type='password'],
textarea,
input[type='button'],
input[type='submit'],
select {
  width: 100%;
  max-width: 100%;
  border: solid rgb(var(--theme-color)) 1px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(var(--theme-color), 0.05);
  transition: filter 0.5s, background 0.5s, border 0.5s;
}

.lbp-field-wrapper .form-control:disabled
{
  background: rgba(var(--theme-color-dark), 0.15);
}

input[type='submit'] {
  width: initial;
  min-width: initial;
  padding: 15px 20px;
}

input[type='submit']:hover {
  border: solid rgb(var(--theme-color-light)) 1px;
  background: rgba(255, 255, 255, 0.5);
  filter: drop-shadow(0px 0px 5px rgb(var(--tertiary-color)));
  color: rgb(var(--theme-color));
}
